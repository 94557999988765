<template>
  <div>
    <skeleton v-if="!backGround" type="actList" />
    <div v-else :style="{'background':'#'+bgColor}">
      <div>
        <img :src="backGround.url" alt>
      </div>
      <!-- <img class="banner"
           :src="banner">
      <img v-if="title != ''"
           class="tit"
      :src="title">-->
      <div v-for="(item,index) in list" :key="index">
        <img class="banner" :src="item.teamBanner">
        <!--模版1-->
        <div v-if="item.teamType == 1" class="activety_list activety_list1">
          <div
            v-for="(jx,jIndex) in item.goodsInfo"
            :key="jIndex"
            class="activety_item"
            @click="godetail(jx)"
          >
            <img :src="jx.goods_img">
            <h1>{{ jx.product_name }}</h1>
            <p>
              <em>¥</em>
              {{ jx.product_price }}
              <span>
                <em>¥</em>
                {{ jx.market_price }}
              </span>
            </p>
          </div>
        </div>
        <!--模版二-->
        <div v-if="item.teamType == 2" class="activety_list activety_list2">
          <div
            v-for="(jx,jIndex) in item.goodsInfo"
            :key="jIndex"
            class="activety_item"
            @click="godetail(jx)"
          >
            <img :src="jx.goods_img">
            <h1>{{ jx.product_name }}</h1>
            <p>
              <em>¥</em>
              {{ jx.product_price }}
              <span>
                <em>¥</em>
                {{ jx.market_price }}
              </span>
            </p>
          </div>
        </div>
        <!--模版三-->
        <div v-if="item.teamType == 3" class="activety_list activety_list3">
          <div
            v-for="(jx, jIndex) in item.goodsInfo"
            :key="jIndex"
            class="activety_item"
            @click="godetail(jx)"
          >
            <img :src="jx.goods_img">
            <h1>{{ jx.product_name }}</h1>
            <p>
              <em>¥</em>
              {{ jx.product_price }}
              <span>
                <em>¥</em>
                {{ jx.market_price }}
              </span>
            </p>
          </div>
        </div>

        <!--模版四-->
        <div class="activity_box">
          <div
            v-if="item.teamType == 4"
            class="activety_list activety_list4"
            :style="'width:'+item.width+'px'"
            @click="godetail(jx)"
          >
            <div v-for="(jx,jIndex) in item.goodsInfo" :key="jIndex" class="activety_item">
              <img :src="jx.goods_img">
              <h1>{{ jx.product_name }}</h1>
              <p>
                <em>¥</em>
                {{ jx.product_price }}
                <span>
                  <em>¥</em>
                  {{ jx.market_price }}
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
import { Toast } from 'vant'

Vue.use(Toast)
import {
  getActivityCategoryInfo,
  getCpsActiveList, activityShareInfo
} from '@/services/activity'
import skeleton from '@/component/skeleton/index'
import { urlConversion } from '@/utils/public'
export default {
  name: 'Cpsopo',
  components: {
    skeleton

  },
  data() {
    return {
      banner: '',
      title: '',
      bgColor: 'd71f28',
      valuetext: ',mmkll',
      backGround: '',
      list: [],
      id: '', // 活动id
      room_id: ''
    }
  },
  created() {
    this.id = this.$route.query.id
    this.room_id = this.$route.query.room_id
    this.robot_send = this.$route.query.robot_send
  },
  mounted() {
    window.RNshareDataChange = this.RNshareDataChange
    this.getActivityList()
    this.getActivityCategory()
  },
  methods: {
    // 注入分享方法
    RNshareDataChange() {
      this.$nextTick(() => {
        const mid = this.$route.query.mid
        if (mid && mid !== '0') {
          const body = {
            uid: mid, // 用户ID
            type: this.$route.query.id, // 活动ID
            shareType: 7 // 分享类型 默认为7
          }
          // 获取活动分享到小程序和分享到海报信息
          activityShareInfo(body).then((res) => {
            if (Number(res.code) === 200) {
              const obj = urlConversion('obj', window.location.href)
              const reCode = res.data.reCode
              const arr = []
              let _url = ''
              let pathUrl = ''
              for (const key in obj.value) {
                if (key !== 'env') {
                  arr.push(key + '=' + obj.value[key])
                }
              }
              _url = obj.Http + '?' + arr.join('&')
              pathUrl =
                '/page/mall/pages/SpecialEvent/SpecialEvent?reCode=' +
                reCode +
                '&id=' +
                this.$route.query.id

              const _Router = {
                url: _url,
                eventName: 'share',
                image: res.data.showImg,
                posterImage: res.data.img,
                title: res.data.title,
                des: res.data.remind,
                path: pathUrl
              }
              this.$store.dispatch('RNrouter', _Router)
            }
          })
        } else {
          const RNloginData = {
            eventName: 'login'
          }
          this.$store.dispatch('RNrouter', RNloginData)
        }
      })
    },
    // 获取详情
    getActivityCategory() {
      const params = {
        id: this.id,
        room_id: this.room_id,
        robot_send: this.robot_send
      }
      getActivityCategoryInfo(params).then((res) => {
        if (Number(res.code) === 200) {
          if (document) {
            document.title = res.data.activityInfo.activityName
          }
          this.backGround = res.data.activityInfo.backGround
        } else {
          Toast(res.msg)
        }
      })
    },
    // 获取列表
    getActivityList() {
      this.id = this.$route.query.id
      const opt = {
        // uid:localStorage.getItem("uid") || '0',
        type: 2,
        id: this.id
        //  page: 1,
        // pageSize: 10
        // room_id: this.room_id,
        // robot_send:this.robot_send
      }
      getCpsActiveList(opt).then((res) => {
        if (res.data.length === 0) {
          this.list = res.data
          return
        }
        // this.gitActhds();
        this.list = res.data
        this.teamHeight = res.data[0].teamHeight / 2
        this.banner = res.data[0].teamBanner
        this.list.map((item, index) => {
          item.width = 120 * (item.goodsInfo.length + 1)
        })
      })
    },
    // 跳转商品详情
    godetail(item) {
      const obj = item

      obj.goodId = obj.product_id
      obj.product_type = obj.productH5Type
      this.$store.dispatch('godetail', obj)
    }
  }
}
</script>
<style lang="less" scoped>
.tit {
  display: block;
}

.activety_list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0 0 15px;
  width: 355px;
  margin: 0 auto;

  .activety_item {
    width: 175px;
    height: 235px;
    border-radius: 5px;
    background: #fff;
    overflow: hidden;
    margin-top: 5px;

    img {
      width: 175px;
      height: 164px;
    }

    h1 {
      font-size: 12px;
      line-height: 15px;
      overflow: hidden;
      display: -webkit-box;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2; /*要显示的行数*/
      -webkit-box-orient: vertical;
      padding: 0 12px;
      height: 0.8rem;
      text-align: left;
    }

    p {
      color: #ff4545;
      font-size: 10px;
      padding: 0 12px;
      text-align: left;
      font-size: 13px;

      span {
        font-size: 12px;
        color: #959595;
        text-decoration: line-through;
      }

      em {
        font-size: 10px;
      }
    }
  }

  .activety_item:nth-child(odd) {
    margin-right: 5px;
  }
}

.activety_list1 {
  padding: 0 0;

  .activety_item {
    width: 355px;
    max-height: 264px;
    margin-top: 10px;
    border-bottom-right-radius: 50px;

    img {
      width: 355px;
      height: auto;
    }
  }

  .activety_item:nth-child(odd) {
    margin-right: 0;
  }
}

.activety_list3 {
  padding: 0 0 15px;

  .activety_item {
    width: 115px;
    height: 170px;
    margin-right: 5px;

    img {
      width: 115px;
      height: 120px;
    }
  }

  .activety_item:nth-child(3n) {
    margin-right: 0;
  }
}

.activity_box {
  padding: 0 5px;
  overflow: scroll;
}

.activity_box {
  overflow-x: scroll;
}

.activety_list4 {
  width: 100%;

  .activety_item {
    width: 115px;
    height: 170px;
    margin-right: 5px;
    float: left;
    overflow: hidden;

    img {
      width: 115px;
      height: 120px;
    }
  }

  .activety_item:nth-child(odd) {
    margin-right: 5px;
  }
}
</style>
